import { Link } from 'gatsby';
import React from 'react';

import logo from '../../assets/images/logos/la_ventures_logo/LawAdvisor Ventures - Logo (dark).svg';
import logoMobile from '../../assets/images/logos/logo_mark/android-chrome-512x512.png';
import forward from "../../assets/images/icons/forward.svg";
import close from "../../assets/images/icons/menu-1.svg";

function ModalDialog({ closeAction, children, scroll }) {

  return (
    <div id="ModalDialogComponent" className="modal fade show" role="dialog" style={{ display: "block" }}>
      <div className={`modal-dialog ${scroll ? "modal-dialog-scrollable" : ""}`} role="document">
        <div className="modal-content  container">
          <div className="modal-header">
            <a href="/" className="d-none d-sm-block"><img className="mb-0 d-none d-sm-block" src={logo} alt="" height="36px" /></a>
            <a onClick={closeAction} className="btn btn-sm  d-none d-sm-block btn-outline-primary float-right">Close <img src={forward} /></a>


              <a className="d-sm-none" href="/"><img className="mb-0 ml-3 d-sm-none" src={logoMobile} alt="" height="36px" /></a>
            <a onClick={closeAction} className="btn btn-sm mr-3 d-sm-none btn-outline-primary btn-close float-right">Close <img src={close} /></a>
          </div>
          <div className={`modal-body`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalDialog;
