import React from 'react';
import { useLocation } from "@reach/router";
import { Helmet } from 'react-helmet';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import Footer from './Footer';
import Header from './Header';
import CookieConsent from '../common/CookieConsent';
import { GOOGLE_RECAPTCHA_KEY }  from '../../utils/variables';

import metaImg from "../../assets/images/ventures_thumbnails/meta-image-la-ventures.png";

import '../../assets/stylesheets/application.scss';

function Layout({ children, pageName, image, title, description, revertColor }) {
  let className = '';
  if (pageName) {
    className = `${className} page-${pageName}`;
  }
  const { pathname } = useLocation();
  const path = pathname;
  return (
    <>
      <Helmet bodyAttributes={{ class: className }}>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        <script src="https://player.vimeo.com/api/player.js"></script>
        <title>{title || "LawAdvisor Ventures"}</title>
        <meta name="description" content={description || ''} />
        <meta name="image" content={image || metaImg} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://lawadvisor.ventures${path}`} />
        <meta property="og:title" content={title || "LawAdvisor Ventures"} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image || metaImg} />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
      </Helmet>
      <div className="content-wrapper">
        <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_KEY}>
          <Header path={path} revertColor={revertColor} />
          <main>
            <div className={`content fade-in ${path.startsWith("/job") ? 'overflow-visible' : ''}`}>
              {children}
            </div>
          </main>
          <Footer />
          <CookieConsent />
        </GoogleReCaptchaProvider>
      </div>
    </>
  );
}

export default Layout;
