import React from 'react';
import { Link } from 'gatsby';

import { deleteAllCookies } from '../../utils/cookies';

import logo from '../../assets/images/logos/LA-Ventures-Logo.svg';
import la_logo from '../../assets/images/logos/LA-Ventures-Logo.svg';

function Footer(props) {
  return (
    <footer>
      <div className="container d-none d-md-block">
        <div className="row">
          <div className="col-6">
            <Link to="/" className="logo">
              <img src={logo} alt="" />
            </Link>
            <div className="content rights mb-2 mt-24">
              © Copyright {new Date().getFullYear()} • LawAdvisor Ventures <br/> All rights reserved.
            </div>
          </div>
          {/* <div className="col-6">
            <div className="content mb-2">
              © Copyright 2020 • LawAdvisor Ventures All rights reserved.
            </div>
          </div> */}
          <div className="col-6 text-right">
            <div className="links">
              <div className="column column-left">
                <div className="header">CONTENT</div>
                <div className="item">
                  <Link to="/">Home</Link>
                </div>
                <div className="item">
                  <Link to="/services">Services</Link>
                </div>
                <div className="item">
                  <Link to="/about-us">About Us</Link>
                </div>
                <div className="item">
                  <Link to="/learn">Learn</Link>
                </div>
                <div className="item">
                  <Link to="/steering-group">Steering Group</Link>
                </div>
                <div className="item">
                  <Link to="/news">News</Link>
                </div>
                <div className="item">
                  <Link to="/careers">Careers</Link>
                </div>
              </div>
              <div className="column column-left">
                <div className="header">LEGAL</div>
                <div className="item">
                  <Link className="item" to="/privacy-policy">Privacy Policy</Link>
                </div>
              </div>
              <div className="column column-right">
                <div className="header">OTHER</div>
                <div className="item">
                  <button style={{padding: 0, textAlign: 'left', border: 'none', backgroundColor: 'transparent', lineHeight: '22.22px', fontWeight: 500}} className="item" onClick={() => {
                    deleteAllCookies();

                    location.reload();
                  }}>
                    Manage your cookies
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-small px-4 d-block d-md-none">
        <div className="row">
          <div className="col-12 py-5">
            <Link to="/" className="logo">
              <img width="80%" src={logo} alt="" />
            </Link>
          </div>
          <div className="col-6">
            <div className="column">
              <div className="header">CONTENT</div>
              <div className="item">
                <Link to="/">Home</Link>
              </div>
              <div className="item">
                <Link to="/services">Services</Link>
              </div>
              <div className="item">
                <Link to="about-us">About Us</Link>
              </div>
              <div className="item">
                <Link to="learn">Learn</Link>
              </div>
              <div className="item">
                <Link to="/news">News</Link>
              </div>
              <div className="item mb-sm-24">
                <Link to="/careers">Careers</Link>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="column">
              <div className="header">LEGAL</div>
              <div className="item">
                <Link className="item" to="/privacy-policy">Privacy Policy</Link>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="column">
              <div className="header mb-16">OTHER</div>
                <div className="item">
                  <button style={{padding: 0, textAlign: 'left', border: 'none', backgroundColor: 'transparent', lineHeight: '22.22px', fontWeight: 500}} className="item" onClick={() => {
                    deleteAllCookies();

                    location.reload();
                  }}>
                    Manage your cookies
                  </button>
                </div>
              </div>
            </div>
          <div className="col-12 d-none d-md-block text-center">
            <div className="content mt-0 mb-2">
              © Copyright 2023. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
