import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import logo from '../../assets/images/logos/la_ventures_logo/LawAdvisor Ventures - Logo (dark).svg';
import logoLight from '../../assets/images/logos/logo-light-web.svg';
import logoMobile from '../../assets/images/logos/logo_mark/logo-mark-mobile.svg';
import menu from '../../assets/images/icons/menu.svg';
import close from '../../assets/images/icons/menu-1.svg';
import JobPost from '../../pages/work-with-us';
import ModalDialog from '../common/ModalDialog';
import NavLink from '../common/NavLink';


function Header({ path, revertColor }) {
  const [currScroll, setCurrScroll] = useState(null);
  const [open, setOpen] = useState(false);
  const [workWithUsDialog, setWorkWithUsDialog] = useState(false);

  const headerClassName = `${currScroll > 32 ? '-sticky' : ''} ${open ? '-open' : ''}`;

  const shouldRevert = (currScroll <= 32 && revertColor) ? true : false;

  useEffect(() => {
    const handler = () => {
      setCurrScroll(window.pageYOffset);
    };
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  return (
    <header className={headerClassName}>
      <div className="main-nav">
        <div className="container">
          <Link className="mb-0 d-none d-sm-block" to="/">
            <img  src={shouldRevert ? logoLight : logo} alt="" height="36px" />
          </Link>
          <Link className="mb-0 d-sm-none" to="/">
            <img  src={logoMobile} alt="" height="36px" />
          </Link>
          <nav className={`navigation ${shouldRevert ? 'revert-color' : ''}`}>
            <ul>
              <li className="item">
                <NavLink to="/services" label="Services" />
              </li>
              <li className="item">
                <NavLink to="/about-us" label="About Us" />
              </li>
              <li className="item">
                <NavLink to="/learn" label="Learn" />
              </li>
              <li className="item">
                <NavLink to="/steering-group" label="Steering Group" />
              </li>
              <li className="item">
                <NavLink to="/news" label="News" />
              </li>
              <li className="item">
                <NavLink to="/careers" label="Careers" />
              </li>
              <li className="item">
                <button
                  id="work-with-us"
                  onClick={() => { setWorkWithUsDialog(true); }}
                  className={`${shouldRevert ? 'btn-outline-white' : 'btn-outline-black'} btn btn-sm`}>
                  Work with us
                </button>
              </li>
            </ul>
          </nav>
          {
            open ? (
              <button
                className="menu-button"
                onClick={() => setOpen(false)}
              >
                <h6>Close</h6>
                <img src={close} className="icon ml-2" height="24px" alt="close" />
              </button>
            ) : (
                <button
                  className="menu-button"
                  onClick={() => setOpen(true)}
                >
                  <h6>Menu</h6>
                  <img src={menu} className="icon ml-2" height="24px" alt="menu" />
                </button>
              )
          }
        </div>
      </div >
      <div className={`mobile-nav ${open ? '-open' : ''}`}>
        <nav className="navigation">
          <ul>
            <li className="item">
              <Link className="nav-link text-muted" to="/services">
                Services
              </Link>
            </li>
            <li className="item">
              <Link className="nav-link text-muted" to="/about-us">
                About Us
              </Link>
            </li>
            <li className="item">
              <Link className="nav-link text-muted" to="/learn">
                Learn
              </Link>
            </li>
            <li className="item">
              <Link className="nav-link text-muted" to="/steering-group">
                Steering Group
              </Link>
            </li>
            <li className="item">
              <Link className="nav-link text-muted" to="/news">
                News
              </Link>
            </li>
            <li className="item">
              <Link className="nav-link text-muted" to="/careers">
                Careers
              </Link>
            </li>
            <li className="item">
              <button
                id="work-with-us-button"
                onClick={() => { setWorkWithUsDialog(true); }}
                className="btn btn-primary w-auto btn-sm ml-0"
              >
              Work with us
              </button>
            </li>
          </ul>
        </nav>
      </div>
      {
        workWithUsDialog &&
        <ModalDialog closeAction={() => setWorkWithUsDialog(false)} scroll={true}>
          <div className="container px-0">
            <JobPost />
          </div>
        </ModalDialog>
      }
    </header >
  );
}

export default Header;
